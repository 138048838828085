import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';

import { DropListBalanceProps } from '@/shared/components/drop-list-balance/types';
import { Illustration } from '@/shared/ui';
import { DropDownWrapper } from '@/shared/ui/desktop/drop-down-wrapper/drop-down-wrapper';
import { AdditionalInfoMobile } from '@/shared/ui/mobile/additional-info';
import { BottomSheetUi } from '@/shared/ui/mobile/bottom-sheet';
import { CurrencyBottomSheet } from '@/shared/ui/mobile/currency-bottom-sheet';
import { Typography } from '@/shared/ui/typography';
import { useStore } from '@/store/context';

import styles from './drop-list-balance.module.scss';

export const DropListBalance: React.FC<DropListBalanceProps> = observer(
  ({ isMobile }) => {
    const [dropListOpened, setDropListOpened] = useState(false);
    const [sheetOpened, setSheetOpened] = useState(false);
    const [currencySheetOpened, setCurrencySheetOpened] = useState(false);

    const auth = useStore().authorization;
    const appStore = useStore().app;
    const currencySign = appStore.getCurrencySign;
    const selectedCurrency = appStore.selectedCurrency;
    const iconCLasses = cn(styles['container-icon'], {
      [styles['container-icon-reversed']]: sheetOpened && isMobile,
    });

    const toggleDropDown = () => {
      setDropListOpened(!dropListOpened);
    };

    const closeSheet = () => {
      setSheetOpened(false);
    };

    const handleCurrencyOpen = () => {
      setCurrencySheetOpened(true);
      setSheetOpened(false);
    };

    const handleCurrencyBackPress = () => {
      setCurrencySheetOpened(false);
      setSheetOpened(true);
    };

    if (!auth.userInfo) {
      return null;
    }

    return (
      <>
        <div
          className={styles.container}
          onClick={() => setSheetOpened(true)}
          onMouseEnter={() => setDropListOpened(true)}
          onMouseLeave={() => setDropListOpened(false)}
        >
          <Typography weight="semi-bold" className={styles['container-title']}>
            {currencySign}
            0.00
          </Typography>
          {isMobile ? (
            <Illustration
              name={'chevron-down'}
              spanTagClassName={iconCLasses}
              style={{ color: '#BFBFBF' }}
              spriteName={'icons'}
            />
          ) : (
            <Illustration
              name={'info-circle'}
              spanTagClassName={iconCLasses}
              style={{ color: '#6941C6' }}
              spriteName={'icons'}
            />
          )}
          {!isMobile && (
            <DropDownWrapper
              close={toggleDropDown}
              isOpened={dropListOpened}
              className={styles.drop_list}
            >
              <div className={styles['container-profile_info']}>
                <Typography
                  weight="semi-bold"
                  className={styles['drop-list-title']}
                >
                  {`${currencySign} 0.00`}
                </Typography>
                <Typography
                  weight="medium"
                  className={styles['drop-list-subtitle']}
                >
                  Your balance
                </Typography>
                <Typography
                  weight="medium"
                  className={styles['drop-list-text']}
                >
                  The returned money is credited to your balance
                </Typography>
                <Typography
                  weight="medium"
                  className={styles['drop-list-text']}
                >
                  you can use it in further purchases partially or fully paying
                  their cost!
                </Typography>
              </div>
            </DropDownWrapper>
          )}
        </div>
        {isMobile && (
          <BottomSheetUi isOpen={sheetOpened} handleClose={closeSheet}>
            <BottomSheetUi.Content className={styles['container-profile_info']}>
              <BottomSheetUi.Header>About balance</BottomSheetUi.Header>
              <div className={styles.description}>
                <Typography weight="medium" className={styles['sheet-text']}>
                  The returned money is credited to your balance you can use it
                  in further purchases partially or fully paying their cost!
                </Typography>
                <AdditionalInfoMobile
                  className={styles['drop-list-text']}
                  variant={'success'}
                  icon={
                    <Illustration
                      style={{ color: 'green' }}
                      name={'tag-price'}
                      spriteName={'icons'}
                      spanTagClassName={styles.icon}
                    />
                  }
                >
                  Your balance: $ 0.00
                </AdditionalInfoMobile>
              </div>
              <BottomSheetUi.Button
                text={'Change currency'}
                iconRight={
                  <Illustration
                    style={{ color: '#FDFDFD' }}
                    name={'square-arrow-right'}
                    spriteName={'icons'}
                  />
                }
                onClick={handleCurrencyOpen}
              />
            </BottomSheetUi.Content>
          </BottomSheetUi>
        )}
        {isMobile && (
          <CurrencyBottomSheet
            isOpened={currencySheetOpened}
            onClose={() => setCurrencySheetOpened(false)}
            onBackPress={handleCurrencyBackPress}
          />
        )}
      </>
    );
  },
);
