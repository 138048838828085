'use client';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import { useIsClient } from 'usehooks-ts';

import { Skeleton, Typography } from '@/shared/ui';
import { useStore } from '@/store/context';

import stylesDesktop from './card-item-info.module.scss';
import { CardItemInfoProps } from './card-item-info.types';
export const CardItemInfo = observer(
  ({
    customStyles,
    info: {
      title,
      price: { old, current },
    },
    onTitleClick = () => {},
    applyHandleClickOnFullCard = false,
    className,
  }: CardItemInfoProps) => {
    const styles = customStyles || stylesDesktop;
    const currencySign = useStore().app.getCurrencySign;
    const isClient = useIsClient();
    const isHydrated = useStore().app.isHydrated && isClient;

    return (
      <div className={cn(styles['info-block'], className)}>
        <div className={cn(styles['info-content'])}>
          {isHydrated ? (
            <div
              onClick={applyHandleClickOnFullCard ? onTitleClick : undefined}
              className={styles['info-price-tags']}
            >
              <Typography className={styles['info-price-current']}>
                {currencySign}
                {current}
              </Typography>
              {old && (
                <Typography
                  className={styles['info-price-old']}
                  decoration={'line-through'}
                >
                  {currencySign}
                  {old}
                </Typography>
              )}
            </div>
          ) : (
            <Skeleton style={{ width: '100%', height: 24 }} />
          )}
          <Typography onClick={onTitleClick} className={styles['info-title']}>
            {title}
          </Typography>
        </div>
      </div>
    );
  },
);
