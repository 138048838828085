import cn from 'clsx';
import React, { ComponentProps, ReactNode } from 'react';

import { Avatar, Button, Illustration, TagInfo, Typography } from '@/shared/ui';

import styles from './card-seller-info.module.scss';
import { CardSellerInfoProps } from './card-seller-info.types';

export const CardSellerInfoEntity = ({
  className,
  bottomSlot,
  src,
  isError,
  playerInfo,
  ...props
}: CardSellerInfoProps) => {
  return (
    <div
      className={cn(
        styles['container'],
        { [styles['error']]: isError },
        className,
      )}
      {...props}
    >
      <div className={styles['icon-text']}>
        <Avatar
          isMobile={true}
          hasNotifications={false}
          isBordered
          className={styles['avatar']}
          src={
            src || 'https://ekonom73.ru/wp-content/uploads/2021/05/unnamed2.jpg'
          }
        />
        <div className={styles['text']}>
          <Typography className={styles['name']}>
            {playerInfo.name || 'Unknown'}
          </Typography>
          {isError ? <TagInfo variant={'error'}>Error</TagInfo> : bottomSlot}
        </div>
      </div>
    </div>
  );
};

// eslint-disable-next-line react/display-name
CardSellerInfoEntity.StatusWrapper = ({
  children,
}: {
  children: ReactNode;
}) => {
  return <div className={styles['status-wrapper']}>{children}</div>;
};
type NavigationButton = ComponentProps<typeof Button> & {
  direction: 'next' | 'prev';
};
// eslint-disable-next-line react/display-name
CardSellerInfoEntity.NavigationButton = ({
  direction,
  isError,
  ...props
}: NavigationButton) => {
  return (
    <Button
      variant={'quaternary'}
      className={cn(
        styles['navigation-btn'],
        { [styles['error']]: isError },
        styles[`navigation-btn--${direction}`],
      )}
      iconRight={
        <Illustration
          spriteName={'icons'}
          name={'left'}
          spanTagClassName={styles['arrow-icon']}
        />
      }
      {...props}
    />
  );
};

export const CardSellersWrapper = ({ children }: { children: ReactNode }) => {
  return <div className={styles['sellers-container']}>{children}</div>;
};
