import { getCookie } from 'cookies-next';
import { useRouter } from 'next/navigation';

import { isRouteProtected } from '@/shared/constants/routes';
import { useStore } from '@/store/context';

export const useProtectedRouter = () => {
  const router = useRouter();
  const authStore = useStore().authorization;
  const push = (href: string) => {
    const token = getCookie('jwt');
    const isProtected = isRouteProtected(href);
    if (!token && isProtected) {
      authStore.setAuthRedirectPath(href);
      authStore.openSignIn();
    } else {
      router.push(href);
    }
  };

  return { push };
};
