import cn from 'clsx';
import { ReactNode } from 'react';

import { DivElementType } from '@/shared/types/common';
import { Illustration, TagInfo } from '@/shared/ui';
import { PaymentMethodIllustrations } from '@/shared/ui/illustration/illustration';

import styles from './card-payment-method.module.scss';

export type CardPaymentMethodProps = DivElementType & {
  children?: ReactNode;
  isActive: boolean;
  toggleActive: () => void;
  paymentMethod: keyof PaymentMethodIllustrations;
  tagValue: string | number;
};

export const CardPaymentMethodEntity = ({
  children,
  className,
  isActive,
  toggleActive,
  paymentMethod,
  tagValue,
  ...props
}: CardPaymentMethodProps) => {
  const cardLiveFeedClasses = cn(
    styles['card-payment-method'],
    { [styles['active']]: isActive },
    className,
  );

  const useIllustrationAsSource =
    paymentMethod === 'visa' || paymentMethod === 'mexico-online-banking';
  // Если использовать лого методов оплаты из спрайта, то картинки отображаются не корректно.

  return (
    <article onClick={toggleActive} className={cardLiveFeedClasses} {...props}>
      {tagValue && (
        <TagInfo className={styles['tag-info']} variant={'discount-2'}>
          {tagValue}
        </TagInfo>
      )}
      <Illustration
        spanTagClassName={styles['payment-method']}
        useSpriteAsSource={!useIllustrationAsSource}
        width={useIllustrationAsSource ? 88 : undefined}
        height={useIllustrationAsSource ? 42 : undefined}
        name={paymentMethod}
      />
      {isActive && (
        <Illustration
          spanTagClassName={styles['check-icon']}
          name={'check-square'}
          spriteName={'icons'}
        />
      )}
    </article>
  );
};
