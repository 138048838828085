export const SIGN_IN = '/api/auth/login';
export const SIGN_UP = '/api/auth/signup';
export const RESET_PASSWORD_REQUEST = '/api/auth-modal-content/password/reset';

export const CHANGE_PASSWORD = '/api/update/password';

export const USER_INFO = '/api/profile';

export const SOCIAL_AUTH = (provider: string) => `/api/auth/login/${provider}`;
export const VERIFY_SOCIAL_AUTH = (provider: string, query: string) =>
  `/api/auth/login/verify/${provider}?${query}`;
