'use client';

import { CardCategoryProps } from '@entities/desktop/cards/card-category/ui/card-category.types';
import cn from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/navigation';

import { Item } from '@/shared/ui';
import { ItemRarityCategoryCard } from '@/shared/ui';
import { Illustration } from '@/shared/ui/illustration';
import { CategoryItemImageByVariant } from '@/shared/ui/item/item-image-by-variant/item-image-by-variant';
import { Typography } from '@/shared/ui/typography';

import desktopStyles from './card-category.module.scss';
export const CardCategoryEntity = ({
  children,
  className,
  item,
  rarity,
  title,
  subtitle,
  categoryPath = '',
  customStyles,
  ...props
}: CardCategoryProps) => {
  const styles = customStyles || desktopStyles;
  const cardLiveFeedClasses = cn(styles['card-category'], className);
  const router = useRouter();

  return (
    <article
      onClick={() => router.push(`/categories/${categoryPath}`)}
      className={cardLiveFeedClasses}
      {...props}
    >
      <div className={styles['titles']}>
        <Typography className={styles['title']} size={'xl'}>
          {title}
        </Typography>
        <div className={styles.link}>
          <Typography className={styles['subtitle']} size={'s'}>
            {subtitle}
          </Typography>
          <Illustration
            name={'square-arrow-right'}
            spriteName={'icons'}
            spanTagClassName={styles['icon']}
          />
        </div>
      </div>
      <div className={styles['item']}>
        <ItemRarityCategoryCard
          className={styles['background']}
          size={'130'}
          variant={rarity}
        />
        <CategoryItemImageByVariant
          className={styles['item']}
          size={'68'}
          variant={item}
        />
      </div>
    </article>
  );
};
