import { createContext, useContext } from 'react';

type TabsContextProps<T> = {
  activeTab: T;
  setActive: (value: T) => void;
  onTabsChange: (value: T) => void;
  defaultActiveTab?: T;
  isSelectable?: boolean;
};

export const TabsContext = createContext<TabsContextProps<any>>({
  activeTab: '',
  setActive: () => {},
  onTabsChange: () => {},
});
export const useTabsContext = () => useContext(TabsContext);
