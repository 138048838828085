import cn from 'clsx';
import { motion } from 'framer-motion';
import React from 'react';

import Checkbox from '@/shared/ui/desktop/check-box/check-box';
import { FilterItemProps } from '@/shared/ui/desktop/filter-item/types';
import { Typography } from '@/shared/ui/typography';

import styles from './filter-item.module.scss';

export const FilterItem: React.FC<FilterItemProps> = ({
  checked,
  label,
  onChange,
  icon,
}) => {
  const labelClasses = cn(styles['filter_item_container-label'], {
    [styles['filter_item_container-active']]: checked,
  });

  return (
    <motion.div
      initial={{ scaleY: 0, translateY: -10, opacity: 0 }}
      animate={{ scaleY: 1, translateY: 0, opacity: 1 }}
      exit={{ scaleY: 0, translateY: -10, opacity: 0 }}
      key={label}
      className={styles.filter_item_container}
    >
      <Checkbox checked={checked} onChange={onChange}>
        <div className={labelClasses}>
          {icon && icon}
          <Typography weight="medium" className={labelClasses}>
            {label}
          </Typography>
        </div>
      </Checkbox>
    </motion.div>
  );
};
