'use client';
import { InputStepperEntity } from '@entities/desktop/input-stepper';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { GlobalItem } from '@/shared/types/common';
import { ButtonStepper } from '@/shared/ui/desktop/button-stepper';
import { InputStepper } from '@/shared/ui/desktop/input-stepper';
import { CartItemType } from '@/store/cart/cart';
import { useStore } from '@/store/context';

import styles from './adjust-item-quantity.module.scss';

type AdjustItemQuantityProps = {
  item: GlobalItem;
  minQuantity?: number;
  status?: CartItemType['status'];
  className?: string;
};

export const AdjustItemQuantityFeature = observer(
  ({ item, minQuantity = 1, status, className }: AdjustItemQuantityProps) => {
    const cart = useStore()?.cart;
    const cartItem = cart?.items?.find(
      currentItem =>
        item?.propertiesSetId === currentItem.propertiesSetId &&
        item.info.age === currentItem.info.age,
    );

    const handleDecrease = () => {
      if (cartItem?.quantity === 1) {
        cart?.removeFromCart(item.propertiesSetId, item.info.age);
      } else {
        cart?.decreaseQuantity(item.propertiesSetId, item.info.age);
      }
    };

    return (
      <InputStepperEntity
        className={cn(
          styles['container'],
          {
            [styles['status-unavailable']]: status === 'unavailable',
          },
          className,
        )}
        leftSlot={
          <ButtonStepper
            stepperType={'minus'}
            disabled={
              status === 'unavailable' ||
              Number(cartItem?.quantity) <= minQuantity
            }
            onPress={handleDecrease}
          />
        }
        middleSlot={
          <InputStepper
            disabled={status === 'unavailable'}
            value={
              isNaN(Number(cartItem?.quantity)) || status === 'unavailable'
                ? 0
                : Number(cartItem?.quantity)
            }
            setValue={value => {
              cart?.setQuantity(item.propertiesSetId, value, item.info.age);
            }}
          />
        }
        rightSlot={
          <ButtonStepper
            disabled={status === 'unavailable'}
            stepperType={'plus'}
            onPress={() => {
              cart?.addToCart({
                ...item,
                status: 'available',
                quantity: cartItem?.quantity ? cartItem?.quantity + 1 : 1,
              });
            }}
          />
        }
      />
    );
  },
);
