import { InputStepperProps } from '@entities/desktop/input-stepper/types';
import cn from 'clsx';
import React, { useState } from 'react';

import { ButtonStepper } from '@/shared/ui/desktop/button-stepper';
import { InputStepper } from '@/shared/ui/desktop/input-stepper';

import styles from './input-stepper.module.scss';

export const InputStepperEntity: React.FC<InputStepperProps> = ({
  leftSlot = null,
  middleSlot = null,
  rightSlot = null,
  className,
}) => {
  const containerClasses = cn(styles.container, className);

  return (
    <div className={containerClasses}>
      {leftSlot}
      {middleSlot}
      {rightSlot}
    </div>
  );
};
