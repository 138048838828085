import cn from 'clsx';

import { Item, ItemRarityLiveFeed, TagCategory, Typography } from '@/shared/ui';

import styles from './card-drop-option.module.scss';
import {
  CardDropOptionEntityItemInfoProps,
  CardDropOptionEntityItemPropertiesProps,
  CardDropOptionEntityItemProps,
  CardDropOptionEntityProps,
} from './card-drop-option.types';
export const CardDropOptionEntity = ({
  leftSlot,
  middleSlot,
  rightSlot,
  className,
  ...props
}: CardDropOptionEntityProps) => {
  return (
    <article
      className={cn(styles['drop-option-container'], className)}
      {...props}
    >
      {leftSlot}
      {middleSlot}
      {rightSlot}
    </article>
  );
};

CardDropOptionEntity.Item = ({
  rarity,
  item,
}: CardDropOptionEntityItemProps) => {
  return (
    <div
      className={cn(styles['option-item-container'], styles['item-paddings'])}
    >
      <ItemRarityLiveFeed
        size={'58'}
        className={cn(styles['item-rarity'], styles['item-paddings'])}
        variant={rarity}
      />
      <Item className={styles['item']} size={'152'} variant={item} />
    </div>
  );
};

CardDropOptionEntity.ItemInfo = ({
  title,
  age,
}: CardDropOptionEntityItemInfoProps) => {
  return (
    <div className={styles['option-item-info-container']}>
      {title && <Typography className={styles['title']}>{title}</Typography>}
      {age && <Typography className={styles['age']}>{age}</Typography>}
    </div>
  );
};

CardDropOptionEntity.ItemProperties = ({
  tagCategory,
  ...props
}: CardDropOptionEntityItemPropertiesProps) => {
  return (
    <div className={styles['option-item-properties-container']} {...props}>
      {tagCategory?.map((tag, idx) => {
        return (
          <TagCategory
            className={styles['category']}
            key={`drop-option-category-${tag}-${idx}`}
            variant={tag}
          />
        );
      })}
    </div>
  );
};
