import cn from 'clsx';

import { PlateProps } from '@/shared/ui/desktop/plate/plate.types';

import styles from './plate.module.scss';

export const Plate = ({
  variant = '10',
  width = 'full-w',
  className,
  ...props
}: PlateProps) => {
  const plateClasses = cn(
    styles.container,
    styles[`variant--${variant}`],
    styles[`width--${width}`],
    className,
  );

  return (
    <div className={plateClasses} {...props}>
      {props.children}
    </div>
  );
};
