import cn from 'clsx';
import React from 'react';

import { Illustration } from '@/shared/ui';
import { ButtonStepperProps } from '@/shared/ui/desktop/button-stepper/types';

import styles from './button-stepper.module.scss';

export const ButtonStepper: React.FC<ButtonStepperProps> = ({
  disabled,
  onPress,
  stepperType,
  className,
  ...props
}) => {
  const containerClasses = cn(
    styles.container,
    {
      [styles['container-disabled']]: disabled,
    },
    className,
  );

  return (
    <button
      role="button"
      className={containerClasses}
      onClick={onPress}
      {...props}
    >
      <Illustration
        name={`square-stepper-${stepperType}`}
        spriteName={'icons'}
        spanTagClassName={styles['icon']}
      />
    </button>
  );
};
