import cn from 'clsx';
import { ReactNode } from 'react';

import { Item, ItemRarityCard, TagCategory } from '@/shared/ui';

import styles from './card-seller-item.module.scss';
import { CardSellerItemProps } from './card-seller-item.types';
export const CardSellerItemEntity = ({
  rarity,
  item,
  tagCategory,
  className,
}: CardSellerItemProps) => {
  return (
    <div className={cn(styles['item'], className)}>
      <div className={styles['center']}>
        <ItemRarityCard
          className={styles['background']}
          size={'50'}
          variant={rarity}
        />
        <Item className={styles['item-variant']} size={'42'} variant={item} />
      </div>

      {Array.isArray(tagCategory) && tagCategory?.length > 0 && (
        <div className={styles['item-tags']}>
          {tagCategory?.map((tag, idx) => {
            return (
              <TagCategory
                key={`seller-tag-${tag}-${idx}`}
                className={styles['tag']}
                variant={tag}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export const CardSellerItemsWrapper = ({
  children,
}: {
  children: ReactNode;
}) => {
  return <div className={styles['wrapper']}>{children}</div>;
};
