import { Currency } from '@utils/AppConfig';
import { observer } from 'mobx-react-lite';
import React, { ReactNode } from 'react';

import { Button, Illustration } from '@/shared/ui';
import { DropListItem } from '@/shared/ui/drop-list-item';
import { CurrencyBottomSheetProps } from '@/shared/ui/mobile/currency-bottom-sheet/types';
import { useStore } from '@/store/context';

import { BottomSheetUi } from '../bottom-sheet';
import styles from './currency-bottom-sheet.module.scss';

type CurrencyItem = {
  label: string;
  value: Currency;
  icon: ReactNode;
};

const currencies: CurrencyItem[] = [
  {
    label: 'USD',
    value: 'usd',
    icon: (
      <Illustration
        spanTagClassName={styles['item-icon']}
        name={'dollar'}
        style={{ color: '#6941C6' }}
        spriteName={'icons'}
      />
    ),
  },
  {
    label: 'EUR',
    value: 'eur',
    icon: (
      <Illustration
        spanTagClassName={styles['item-icon']}
        name={'euro'}
        style={{ color: '#6941C6' }}
        spriteName={'icons'}
      />
    ),
  },
];

export const CurrencyBottomSheet = observer(
  ({ onClose, onBackPress, isOpened }: CurrencyBottomSheetProps) => {
    const appStore = useStore().app;

    const onSelect = (item: CurrencyItem) => {
      appStore.setCurrency(item.value);
      onClose();
    };

    const renderItem = (item: CurrencyItem, index: number) => {
      return (
        <DropListItem
          className={styles['item']}
          isSelected={item.value === appStore.selectedCurrency}
          key={index}
          onPress={() => onSelect(item)}
          title={item.label}
          iconLeft={item.icon}
        />
      );
    };

    return (
      <BottomSheetUi isOpen={isOpened} handleClose={onClose}>
        <BottomSheetUi.Content className={styles['container-profile_info']}>
          <div className={styles.header}>
            {onBackPress && (
              <Button
                className={styles.backButton}
                onClick={onBackPress}
                width={'content'}
                variant={'quaternary'}
                iconLeft={
                  <Illustration name={'arrow-left'} spriteName={'icons'} />
                }
              />
            )}
            <BottomSheetUi.Header>Currency</BottomSheetUi.Header>
          </div>
          <div className={styles.content}>{currencies.map(renderItem)}</div>
          <BottomSheetUi.Button text={'Close'} onClick={onClose} />
        </BottomSheetUi.Content>
      </BottomSheetUi>
    );
  },
);
