import { AdvertisingProps } from '@entities/advertising/ui/advertising.types';
import cn from 'clsx';

import { Illustration } from '@/shared/ui/illustration';

import styles from './advertising.module.scss';
export const AdvertisingEntity = ({
  className,
  leftSlot = null,
  middleSlot = null,
  rightSlot = null,
  ...props
}: AdvertisingProps) => {
  const advertisingClasses = cn(styles['advertising'], className);

  return (
    <div className={advertisingClasses} {...props}>
      <Illustration
        id={'adv-left-illustration'}
        spanTagClassName={cn(
          styles['adv-illustration'],
          styles['adv-illustration--left'],
        )}
        name={'adv-illustration'}
      />
      <Illustration
        id={'adv-right-illustration'}
        spanTagClassName={cn(
          styles['adv-illustration'],
          styles['adv-illustration--right'],
        )}
        name={'adv-illustration'}
      />
      {leftSlot && (
        <div id={'adv-left-slot'} className={styles['left-slot']}>
          {leftSlot}
        </div>
      )}
      {middleSlot && (
        <div id={'adv-mid-slot'} className={styles['middle-slot']}>
          {middleSlot}
        </div>
      )}
      {rightSlot && (
        <div id={'adv-right-slot'} className={styles['right-slot']}>
          {rightSlot}
        </div>
      )}
    </div>
  );
};
