import React, { useEffect, useRef, useState } from 'react';
import AnimateHeight from 'react-animate-height';

import { HeightAnimatedWrapperProps } from '@/shared/ui/height-animated-wrapper/types';

import styles from './height-animated-wrapper.module.scss';

export const HeightAnimatedWrapper: React.FC<HeightAnimatedWrapperProps> = ({
  duration = 500,
  className,
  children,
}) => {
  const [height, setHeight] = useState<'auto' | number>('auto');
  const contentDiv = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const element = contentDiv.current as HTMLDivElement;

    const resizeObserver = new ResizeObserver(() => {
      setHeight(element.clientHeight);
    });

    resizeObserver.observe(element);

    return () => resizeObserver.disconnect();
  }, []);

  return (
    <AnimateHeight
      contentClassName={className}
      className={styles.container}
      duration={duration}
      contentRef={contentDiv}
      height={height}
      disableDisplayNone
    >
      {children}
    </AnimateHeight>
  );
};
