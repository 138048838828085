import cn from 'clsx';

import { Illustration } from '@/shared/ui';
import { Typography } from '@/shared/ui/typography';

import styles from './additional-info.module.scss';
import {
  AdditionalInfoIconSvgProps,
  AdditionalInfoProps,
} from './additional-info.types';

export const AdditionalInfo = ({
  className,
  children,
  variant,
  width = 'content',
  align = 'start',
  icon = <AdditionalInfo.DefaultIcon variant={variant} />,
  ...props
}: AdditionalInfoProps) => {
  const additionalInfoClasses = cn(
    styles['additional-info'],
    styles[`variant-${variant}`],
    styles[`width-${width}`],
    styles[`align-${align}`],
    className,
  );

  /*@TODO variant danger добавить*/

  return (
    <div className={additionalInfoClasses} {...props}>
      <Typography className={styles['info']}>{children}</Typography>
      {icon}
    </div>
  );
};

// eslint-disable-next-line react/display-name
AdditionalInfo.DefaultIcon = ({
  size,
  width,
  height,
  variant,
  name,
  ...props
}: AdditionalInfoIconSvgProps) => {
  return (
    <Illustration
      spanTagClassName={cn(
        styles['additional-info-icon'],
        styles[`additional-info-icon--${variant}`],
      )}
      spriteName={'icons'}
      name={
        name
          ? name
          : variant === 'success'
            ? 'info-square'
            : `info-square-${variant}`
      }
    />
  );
};
