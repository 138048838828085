'use client';
// eslint-disable
import { useWindowResize } from '@hooks/client';
import { useFocusRing } from '@react-aria/focus';
import { useNumberFormatter } from '@react-aria/i18n';
import { AriaSliderProps, useSlider, useSliderThumb } from '@react-aria/slider';
import { mergeProps } from '@react-aria/utils';
import { VisuallyHidden } from '@react-aria/visually-hidden';
import { SliderState, useSliderState } from '@react-stately/slider';
import cn from 'clsx';
import React, {
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import styles from './range-slider.module.scss';

type RangeSliderProps = {
  formatOptions?: Intl.NumberFormatOptions;
  externalValue: [number, number];
} & AriaSliderProps;

type RangeSliderThumbProps = {
  state: SliderState;
  trackRef: RefObject<any>;
  inputThumbRef: RefObject<HTMLInputElement>;
  divThumbRef: RefObject<HTMLInputElement>;
  index: number;
  name?: string;
};

export const RangeSlider = (props: RangeSliderProps) => {
  let trackRef = useRef<HTMLDivElement>(null);
  let inputThumbRefLeft = useRef<HTMLInputElement>(null);
  let inputThumbRefRight = useRef<HTMLInputElement>(null);
  let divThumbRefRight = useRef<HTMLInputElement>(null);
  let divThumbRefLeft = useRef<HTMLInputElement>(null);
  let pseudoTrackRef = useRef<HTMLDivElement>(null);

  let numberFormatter = useNumberFormatter(props.formatOptions);
  let state = useSliderState({ ...props, numberFormatter });
  let { groupProps, trackProps, labelProps, outputProps } = useSlider(
    props,
    state,
    trackRef,
  );

  const { windowSize } = useWindowResize();

  useEffect(() => {
    if (
      pseudoTrackRef?.current &&
      trackRef?.current &&
      Array.isArray(props.defaultValue)
    ) {
      const _trackWidth = trackRef?.current.getBoundingClientRect().width;
      const _pxls =
        trackRef?.current.getBoundingClientRect().width / props.defaultValue[1];
      const _fromStartToLeftThumbPx = _pxls * props.externalValue[0];
      const _fromStartToRightThumbPx = _pxls * props.externalValue[1];
      const _pseudoTrackWidth =
        _fromStartToRightThumbPx - _fromStartToLeftThumbPx;
      pseudoTrackRef.current.style.transform =
        _pseudoTrackWidth >= 0 && _pseudoTrackWidth <= _trackWidth
          ? `translateX(${_fromStartToLeftThumbPx}px)`
          : '';
      pseudoTrackRef.current.style.width =
        _pseudoTrackWidth >= 0 && _pseudoTrackWidth <= _trackWidth
          ? `${_pseudoTrackWidth}px`
          : `${_trackWidth}px`;
      pseudoTrackRef.current.style.maxWidth =
        _fromStartToRightThumbPx > _trackWidth
          ? `${_trackWidth}px`
          : `${_fromStartToRightThumbPx}px`;
    }
  }, [
    props.externalValue[0],
    props.externalValue[1],
    props.defaultValue,
    windowSize,
  ]);

  return (
    <div
      {...groupProps}
      className={cn(styles['slider'], styles[`${state.orientation}`])}
    >
      {props.label && (
        <div className={styles['label-container']}>
          <label {...labelProps}>{props.label}</label>
          <output {...outputProps}>
            {`${state.getThumbValueLabel(0)} - ${state.getThumbValueLabel(1)}`}
          </output>
        </div>
      )}
      <div
        {...trackProps}
        ref={trackRef}
        className={cn(styles['track'], {
          [styles['disable']]: state.isDisabled,
        })}
      >
        <div className={styles['pseudo-track']} ref={pseudoTrackRef} />
        <RangeSlider.Thumb
          divThumbRef={divThumbRefLeft}
          inputThumbRef={inputThumbRefLeft}
          index={0}
          state={state}
          trackRef={trackRef}
        />
        <RangeSlider.Thumb
          divThumbRef={divThumbRefRight}
          inputThumbRef={inputThumbRefRight}
          index={1}
          state={state}
          trackRef={trackRef}
        />
      </div>
    </div>
  );
};

// eslint-disable-next-line react/display-name
RangeSlider.Thumb = (props: RangeSliderThumbProps) => {
  let { state, trackRef, index, name } = props;
  // eslint-disable-next-line react-hooks/rules-of-hooks

  // eslint-disable-next-line react-hooks/rules-of-hooks
  let { thumbProps, inputProps, isDragging } = useSliderThumb(
    {
      index,
      trackRef,
      inputRef: props.inputThumbRef,
      name,
    },
    state,
  );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  let { focusProps, isFocusVisible } = useFocusRing();
  return (
    <div
      {...thumbProps}
      ref={props.divThumbRef}
      className={cn(
        styles['thumb'],
        { [styles['focus']]: isFocusVisible },
        { [styles['dragging']]: isDragging },
      )}
    >
      <VisuallyHidden>
        <input
          ref={props.inputThumbRef}
          {...mergeProps(inputProps, focusProps)}
        />
      </VisuallyHidden>
    </div>
  );
};
