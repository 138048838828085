import {
  SIDEBAR_CATEGORIES_FILTERS,
  TRANSPORT_FILTERS,
} from '@widgets/desktop/sidebar/ui/sidebar';

import { SORTING_FILTERS_BY_VARIANT } from '@/shared/components/drop-list-sort/items';
import { Filter } from '@/shared/types/common';

/*
export const PROPERTY_LABELS = [
  'Default',
  'Neon',
  'Mega-Neon',
  'Fly',
  'Ride',
  'Pets',
  'Eggs',
  'Transport',
  'Potion',
  'Exclusive',
];
export const RARITY_LABELS = [
  'Common',
  'Uncommon',
  'Rare',
  'Ultra-Rare',
  'Legendary',
];
*/

export const HOT_SALE_FILTERS: Filter[] = [
  {
    label: 'Hot Sale',
    filter: 'hot-sale',
    group: 'sale',
  },
];
export const NEW_FILTERS: Filter[] = [
  {
    label: 'New',
    filter: 'new',
    icon: 'lightning',
    group: 'category',
  },
];

export const PROPERTIES_FILTERS: Filter[] = [
  {
    group: 'property',
    label: 'Default',
    icon: 'none',
    filter: 'default',
  },
  {
    group: 'property',
    label: 'Neon',
    icon: 'neon',
    filter: 'neon',
  },
  {
    group: 'property',
    label: 'Mega-Neon',
    icon: 'mega-neon',
    filter: 'mega-neon',
  },
  {
    group: 'property',
    label: 'Fly',
    icon: 'fly',
    filter: 'fly',
  },
  {
    group: 'property',
    label: 'Ride',
    icon: 'ride',
    filter: 'ride',
  },
];
export const CATEGORY_FILTERS: Filter[] = [
  {
    group: 'category',
    label: 'Pets',
    icon: 'cat',
    filter: 'pets',
  },
  {
    group: 'category',
    label: 'Eggs',
    icon: 'egg',
    filter: 'eggs',
  },
  {
    group: 'category',
    label: 'Transport',
    icon: 'bus',
    filter: 'transport',
  },
  {
    group: 'category',
    label: 'Potion',
    icon: 'potion',
    filter: 'potions',
  },
  {
    group: 'category',
    label: 'Exclusive',
    icon: 'exclusive',
    filter: 'exclusive',
  },
];
export const RARITY_FILTERS: Filter[] = [
  {
    group: 'rarity',
    label: 'Common',
    icon: 'common',
    filter: 'common',
  },
  {
    label: 'Uncommon',
    icon: 'uncommon',
    filter: 'uncommon',
    group: 'rarity',
  },
  {
    label: 'Rare',
    icon: 'rare',
    filter: 'rare',
    group: 'rarity',
  },
  {
    label: 'Ultra-Rare',
    icon: 'ultra-rare',
    filter: 'ultra-rare',
    group: 'rarity',
  },
  {
    label: 'Legendary',
    icon: 'legendary',
    filter: 'legendary',
    group: 'rarity',
  },
];
export const AGE_FILTERS: Filter[] = [
  {
    group: 'age',
    label: 'Newborn',
    filter: 'newborn',
  },
  {
    group: 'age',
    label: 'Junior',
    filter: 'junior',
  },
  {
    group: 'age',
    label: 'Pre-teen',
    filter: 'pre-teen',
  },
  {
    group: 'age',
    label: 'Teen',
    filter: 'teen',
  },
  {
    group: 'age',
    label: 'Post-teen',
    filter: 'post-teen',
  },
  {
    group: 'age',
    label: 'Full-grow',
    filter: 'full-grow',
  },
  {
    group: 'age',
    label: 'Reborn',
    filter: 'reborn',
  },
  {
    group: 'age',
    label: 'Twinkle',
    filter: 'twinkle',
  },
  {
    group: 'age',
    label: 'Sparkle',
    filter: 'sparkle',
  },
  {
    group: 'age',
    label: 'Flare',
    filter: 'flare',
  },
  {
    group: 'age',
    label: 'Sunshine',
    filter: 'sunshine',
  },
  {
    group: 'age',
    label: 'Luminous',
    filter: 'luminous',
  },
];

export const PRICE_FILTERS: Filter[] = [
  {
    group: 'priceFrom',
    label: '',
    filter: '',
  },
  {
    group: 'priceTo',
    label: '',
    filter: '',
  },
];
