import cn from 'clsx';
import React, { ChangeEvent } from 'react';

import styles from './input-stepper.module.scss';
import { InputStepperProps } from './types';

export const InputStepper: React.FC<InputStepperProps> = ({
  disabled,
  value,
  setValue,
  className,
  mRef,
  ...props
}) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    if (/^\d+$/.test(newValue)) {
      setValue(Number(newValue));
    } else if (!newValue) {
      setValue(0);
    }
  };
  const containerClasses = cn(
    styles.container,
    {
      [styles['container-disabled']]: disabled,
    },
    className,
  );

  return (
    <input
      ref={mRef}
      disabled
      value={value}
      onChange={handleChange}
      type="text"
      className={containerClasses}
      {...props}
    ></input>
  );
};
