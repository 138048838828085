import CheckIcon from '@public/assets/build/icons/check-circle.svg';
import CrossIcon from '@public/assets/build/icons/close-circle.svg';
import cn from 'clsx';
import React from 'react';

import styles from './switch.module.scss';

interface SwitchProps {
  variant: string;
  isChecked: boolean;
  setIsChecked: () => void;
  disabled?: boolean;
  className?: string;
}

export const Switch: React.FC<SwitchProps> = ({
  isChecked,
  setIsChecked,
  disabled,
  className,
}) => {
  const sliderClasses = cn(styles.slider, {
    [styles['slider-disabled']]: disabled,
  });

  return (
    <label className={cn(styles.switch, className)}>
      <input
        className={styles.checkbox}
        type="checkbox"
        checked={isChecked}
        onChange={() => setIsChecked()}
      ></input>
      <span id={'switch-slider'} className={sliderClasses}>
        <div className={styles.toggle}>
          {!isChecked ? <CrossIcon /> : <CheckIcon />}
        </div>
      </span>
    </label>
  );
};
