import { useDevice } from '@hooks/server';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useMediaQuery } from 'usehooks-ts';
import * as Yup from 'yup';

import { CardPlayerInfoEntity } from '@/entities';
import { Description, IllustrationModal, ModalContent } from '@/shared/ui';
import { AdditionalInfo } from '@/shared/ui/desktop/additional-info';
import { InputAuth } from '@/shared/ui/desktop/input-auth';
import { AdditionalInfoMobile } from '@/shared/ui/mobile/additional-info';
import { useStore } from '@/store/context';

import styles from './select-account.module.scss';

const mockedPlayers = [
  {
    id: 0,
    src: '',
    playerInfo: {
      nickname: 'Z0xFraud-',
      name: 'Zeekr',
    },
  },
  {
    id: 1,
    src: '',
    playerInfo: {
      nickname: 'shamelessZ0x',
      name: 'WooDoo',
    },
  },
  {
    id: 2,
    src: '',
    playerInfo: {
      nickname: 'HeroWithNoFear',
      name: 'John Doe',
    },
  },
  {
    id: 3,
    src: '',
    playerInfo: {
      nickname: 'aheles',
      name: 'Son of a Bitch',
    },
  },
  {
    id: 4,
    src: '',
    playerInfo: {
      nickname: 'PortugalPirate',
      name: 'Bae of Loon',
    },
  },
] as const;

export const SelectAccountFeature = observer(() => {
  const withdrawal = useStore()?.withdrawal;
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const formik = useFormik({
    initialValues: {
      nickname: '',
    },
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: Yup.object({
      nickname: Yup.string()
        .max(15, 'Must be 15 characters or less')
        .required('Required'),
    }),
    onSubmit: values => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  return (
    <ModalContent
      className={styles['container']}
      topRightSlot={
        <ModalContent.CloseButton onClick={() => withdrawal?.close()} />
      }
    >
      <IllustrationModal variant={'select-account'} />
      <ModalContent.Title
        title={'Select account'}
        subtitle={'Item withdrawal'}
      />

      <ModalContent.Body>
        <InputAuth
          value={formik.values.nickname}
          iconName={'user'}
          onChange={formik.handleChange('nickname')}
          onBlur={formik.handleBlur('nickname')}
          placeholder="Nickname Roblox"
          errorMessage={formik.touched.nickname ? formik.errors.nickname : ''}
          withErrorMessage={false}
        />
        {!formik.values.nickname && (
          <Description
            message={'Please enter your nickname or check if it is correct!'}
          />
        )}

        {formik.values.nickname && (
          <div className={styles['players-container']}>
            {mockedPlayers.map((player, idx) => {
              return (
                <CardPlayerInfoEntity
                  key={player.id}
                  src={player.src}
                  playerInfo={player.playerInfo}
                  onClick={() => {
                    //@todo логика выбора акканута

                    withdrawal?.setNextStep('sellerVerification');
                  }}
                />
              );
            })}
          </div>
        )}
      </ModalContent.Body>

      {!formik.values.nickname && (
        <ModalContent.Footer>
          {isDesktop ? (
            <AdditionalInfo
              align={'center'}
              width={'full-w'}
              variant={'success'}
              icon={
                <AdditionalInfo.DefaultIcon variant={'success'} name={'bag'} />
              }
            >
              Selected items: {withdrawal?.selectedIds.length}
            </AdditionalInfo>
          ) : (
            <AdditionalInfoMobile
              width={'full-w'}
              variant={'success'}
              icon={
                <AdditionalInfoMobile.DefaultIcon
                  variant={'success'}
                  name={'bag'}
                />
              }
            >
              Selected items: {withdrawal?.selectedIds.length}
            </AdditionalInfoMobile>
          )}
        </ModalContent.Footer>
      )}
    </ModalContent>
  );
});
