import cn from 'clsx';
import Image from 'next/image';

import { Button, IllustrationModal, Typography } from '@/shared/ui';

import styles from './sidebar-favorites.module.scss';
import {
  SidebarFavoritesActionButton,
  SidebarFavoritesImageProps,
  SidebarFavoritesInfo,
  SidebarFavoritesProps,
} from './sidebar-favorites.types';
export const SidebarFavoritesEntity = ({
  topSlot,
  middleSlot,
  bottomSlot,
  className,
  ...props
}: SidebarFavoritesProps) => {
  const sidebarFavClasses = cn(styles['container'], className);

  return (
    <div className={sidebarFavClasses} {...props}>
      {topSlot}
      {middleSlot}
      {bottomSlot}
    </div>
  );
};

// eslint-disable-next-line react/display-name
SidebarFavoritesEntity.Image = ({
  variant,
  ...props
}: SidebarFavoritesImageProps) => {
  return (
    <IllustrationModal
      variant={variant}
      style={{ objectFit: 'cover' }}
      {...props}
    />
  );
};

// eslint-disable-next-line react/display-name
SidebarFavoritesEntity.Info = ({
  info: { title, subtitle },
  className,
}: SidebarFavoritesInfo) => {
  return (
    <div className={cn(styles['sidebar-info'], className)}>
      <Typography className={styles['title']}>{title}</Typography>

      {subtitle && (
        <Typography className={styles['subtitle']}>{subtitle}</Typography>
      )}
    </div>
  );
};

// eslint-disable-next-line react/display-name
SidebarFavoritesEntity.ActionButton = ({
  ...props
}: SidebarFavoritesActionButton) => {
  return (
    <Button width={'full-w'} className={styles['sidebar-btn']} {...props} />
  );
};
