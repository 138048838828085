import { observer } from 'mobx-react-lite';
import React from 'react';
import { useMediaQuery } from 'usehooks-ts';

import { IllustrationModal, ModalContent } from '@/shared/ui';
import { AdditionalInfo } from '@/shared/ui/desktop/additional-info';
import { AdditionalInfoMobile } from '@/shared/ui/mobile/additional-info';
import { useStore } from '@/store/context';

import styles from './success.module.scss';

export const WithdrawalSuccessFeature = observer(() => {
  const withdrawal = useStore()?.withdrawal;
  const isDesktop = useMediaQuery('(min-width: 1024px)');

  return (
    <ModalContent
      className={styles['container']}
      topRightSlot={
        <ModalContent.CloseButton onClick={() => withdrawal?.close()} />
      }
    >
      <IllustrationModal variant={'success'} />
      <ModalContent.Title
        title={'Congratulations'}
        subtitle={'The purchase is completed'}
      />

      <ModalContent.Footer height={'full-h'}>
        {isDesktop ? (
          <AdditionalInfo
            align={'center'}
            width={'full-w'}
            variant={'success'}
            icon={
              <AdditionalInfo.DefaultIcon
                variant={'success'}
                name={'check-circle'}
              />
            }
          >
            All items have been successfully withdrawn!
          </AdditionalInfo>
        ) : (
          <AdditionalInfoMobile
            width={'full-w'}
            variant={'success'}
            icon={
              <AdditionalInfoMobile.DefaultIcon
                variant={'success'}
                name={'check-circle'}
              />
            }
          >
            All items have been successfully withdrawn!
          </AdditionalInfoMobile>
        )}
        <ModalContent.Button
          variant={'primary'}
          text={'Close'}
          onClick={() => {
            //withdrawal?.close();
            withdrawal?.setNextStep('withdrawalError');
          }}
        />
      </ModalContent.Footer>
    </ModalContent>
  );
});
