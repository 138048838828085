import cn from 'clsx';
import { useState } from 'react';

import { InputPrice } from '@/shared/ui/desktop/input-price';
import { RangeSlider } from '@/shared/ui/slider/range-slider';
import { ChangeResult, SliderProps } from '@/shared/ui/slider/slider.types';
import { Typography } from '@/shared/ui/typography';

import styles from './slider.module.scss';
export const SliderWithInputs = ({
  min,
  max,
  minValue: externalMinValue,
  maxValue: externalMaxValue,
  handleChange,
  className,
}: SliderProps) => {
  const containerClasses = cn(styles.container, className);
  const [minValue, setMinValue] = useState(externalMinValue);
  const [maxValue, setMaxValue] = useState(externalMaxValue);

  const saveMinValue = (value: number) => {
    if (value < max) {
      setMinValue(value);
      handleChange({ min: value, max: maxValue });
    } else {
      handleChange({ min, max });
    }
  };

  const saveMaxValue = (value: number) => {
    if (value > min && value <= max) {
      setMaxValue(value);
      handleChange({ min: minValue, max: value });
    } else {
      handleChange({ min, max });
    }
  };

  const handleSliderChange = (e: ChangeResult) => {
    if (e.minValue < e.maxValue) {
      setMinValue(e.minValue);
      setMaxValue(e.maxValue);
      handleChange({ min: e.minValue, max: e.maxValue });
    }
  };

  return (
    <div className={containerClasses}>
      <Typography className={styles.title} weight="semi-bold">
        Item Price
      </Typography>
      <div className={styles['inputs-container']}>
        <InputPrice
          setValue={setMinValue}
          label="From"
          value={minValue < maxValue ? minValue : min}
          minValue={min}
          maxValue={maxValue}
          onSave={saveMinValue}
        />
        <InputPrice
          setValue={setMaxValue}
          label="To"
          value={maxValue > minValue ? maxValue : max}
          minValue={minValue}
          maxValue={max}
          onSave={saveMaxValue}
        />
      </div>
      <div className={styles['slider-wrapper']}>
        <RangeSlider
          aria-label={'currency-range'}
          formatOptions={{
            style: 'currency',
            currency: 'USD',
          }}
          onChange={value => {
            if (Array.isArray(value)) {
              value[0] < value[1] &&
                handleSliderChange({
                  minValue: value[0],
                  maxValue: value[1],
                  min: value[0],
                  max: value[1],
                });
            }
          }}
          value={minValue < maxValue ? [minValue, maxValue] : [min, max]}
          externalValue={
            minValue < maxValue ? [minValue, maxValue] : [min, max]
          }
          maxValue={max}
          defaultValue={[min, max]}
          step={1}
        />
      </div>
    </div>
  );
};
