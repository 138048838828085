import cn from 'clsx';

import { Illustration } from '@/shared/ui';

import styles from './item-rarity-shop-card-bg.module.scss';
import { ItemRarityLiveFeedIconSvgProps } from './item-rarity-shop-card-bg.types';

export const colorsByVariant = {
  common: 'var(--global-blue400)',

  uncommon: 'var(--global-purple400)',
  'ultra-rare': 'var(--global-red400)',
  rare: 'var(--global-green400)',
  legendary: 'var(--global-rainmaker400)',
} as const;

export const ItemRarityShopCardBg = ({
  variant,
  size,
  className,
  style,
  ...props
}: ItemRarityLiveFeedIconSvgProps) => {
  const itemRarityShopCardBgClasses = cn(
    styles['item-rarity-shop-card-bg'],
    styles[`size--${size}`],
    className,
  );

  return (
    <Illustration
      style={{ color: colorsByVariant[variant], ...style }}
      spanTagClassName={itemRarityShopCardBgClasses}
      name={'item-rarity-shop-card-bg-1'}
    />
  );
};
