import {
  ToastEntityIconProps,
  ToastEntityProps,
} from '@entities/toast/ui/toast.types';
import cn from 'clsx';

import { Illustration } from '@/shared/ui';
import { Button } from '@/shared/ui/button';
import { Typography } from '@/shared/ui/typography';

import styles from './toast.module.scss';

export const ToastEntity = ({
  className,
  variant = 'balance',
  bottomSlot = <></>,
  handleClose = () => {},
  title,
  subtitle,
  ...props
}: ToastEntityProps) => {
  const entityClasses = cn(styles['container'], className);

  return (
    <div className={entityClasses} {...props}>
      <div className={styles['top-slot']}>
        <div className={styles['top-slot__left']}>
          <div className={styles['top-slot__left-icon']}>
            <ToastEntity.Icon variant={variant} />
          </div>
          <div className={styles['top-slot__left-text-block']}>
            <Typography className={styles['top-slot__left-text-block-title']}>
              {title}
            </Typography>
            {subtitle && (
              <Typography
                className={styles['top-slot__left-text-block-subtitle']}
              >
                {subtitle}
              </Typography>
            )}
          </div>
        </div>

        <div className={styles['top-slot__right']}>
          <Button
            iconLeft={
              <Illustration
                style={{ color: '#9E9D9E' }}
                spanTagClassName={styles['x-close']}
                name={'x-close'}
                spriteName={'icons'}
              />
            }
            variant={'quaternary'}
            className={styles['top-slot__right-close-btn']}
            onClick={() => handleClose()}
          />
        </div>
      </div>

      <div className={styles['bottom-slot']}>{bottomSlot}</div>
    </div>
  );
};
const infoByVariant = {
  success: {
    illustrations: 'toast-info-check',
    icon: 'var(--global-green600)',
  },
  error: {
    illustrations: 'toast-info-error',
    icon: 'var(--global-red600)',
  },
  warn: {
    illustrations: 'toast-info-warn',
    icon: 'var(--global-orange600)',
  },
  balance: {
    illustrations: 'toast-info',
    icon: 'var(--global-blue600)',
  },
  discount: {
    illustrations: 'toast-tag-price',
    icon: 'var(--global-purple600)',
  },
  password: {
    illustrations: 'toast-info-password',
    icon: 'var(--global-orange600)',
  },
} as const;

// eslint-disable-next-line react/display-name
ToastEntity.Icon = ({ variant }: ToastEntityIconProps) => {
  return (
    variant && (
      <Illustration
        style={{ color: infoByVariant[variant]?.icon }}
        spanTagClassName={styles['background-icon']}
        name={infoByVariant[variant]?.illustrations}
      />
    )
  );
};
