import cn from 'clsx';
import { DetailedHTMLProps, HTMLAttributes } from 'react';

import { Button, Illustration } from '@/shared/ui';

import styles from './close-button.module.scss';

export type AdvertisingCloseButtonProps = DetailedHTMLProps<
  HTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;
export const AdvertisingCloseButton = ({
  className,
  onClick,
  ...props
}: AdvertisingCloseButtonProps) => {
  const advCloseButtonClasses = cn(styles['close-button'], className);

  return (
    <Button
      variant={'quaternary'}
      onClick={onClick}
      className={advCloseButtonClasses}
      {...props}
      iconLeft={
        <Illustration
          spanTagClassName={styles['icon-black']}
          name={'x-close'}
          spriteName={'icons'}
        />
      }
    />
  );
};
