'use client';
import {
  CardLiveFeedEntityItemInfoProps,
  CardLiveFeedEntityItemProps,
  CardLiveFeedEntityTagsProps,
  CardLiveFeedProps,
} from '@entities/desktop/cards/card-live-feed/ui/card-live-feed.types';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import { useIsClient } from 'usehooks-ts';

import { Item, Skeleton } from '@/shared/ui';
import { ItemRarityLiveFeed } from '@/shared/ui';
import { TagCategory } from '@/shared/ui/tag-category';
import { Typography } from '@/shared/ui/typography';
import { useStore } from '@/store/context';

import styles from './card-live-feed.module.scss';

export const CardLiveFeedEntity = ({
  children,
  className,
  tagCategory,
  item,
  rarity,
  info,
  topSlot = <CardLiveFeedEntity.Item rarity={rarity} item={item} />,
  bottomSlot = tagCategory?.length &&
    tagCategory.length > 0 &&
    tagCategory?.map((category, idx) => (
      <TagCategory
        className={styles['tag-category']}
        key={`clfc-${category}-idx-${idx}`}
        variant={category}
      />
    )),
  ...props
}: CardLiveFeedProps) => {
  const cardLiveFeedClasses = cn(styles['card-live-feed'], className);

  return (
    <article className={cardLiveFeedClasses} {...props}>
      <div className={cn(styles['card-live-feed--content'])}>
        <div className={cn(styles['card-live-feed--content__info'])}>
          <CardLiveFeedEntity.ItemInfo info={info} />
        </div>
        <div className={cn(styles['card-live-feed--content__item'])}>
          {topSlot}
        </div>
        {tagCategory && (
          <CardLiveFeedEntity.Tags>{bottomSlot}</CardLiveFeedEntity.Tags>
        )}
      </div>
    </article>
  );
};

// eslint-disable-next-line react/display-name
CardLiveFeedEntity.Tags = ({ children }: CardLiveFeedEntityTagsProps) => {
  const cardLiveFeedEntityTagsClasses = cn(styles['card-live-feed--tags']);

  return <div className={cardLiveFeedEntityTagsClasses}>{children}</div>;
};

// eslint-disable-next-line react/display-name
CardLiveFeedEntity.Item = ({ item, rarity }: CardLiveFeedEntityItemProps) => {
  return (
    <>
      <ItemRarityLiveFeed
        size={'58'}
        className={styles['item-rarity']}
        variant={rarity}
      />
      <Item className={styles['item']} size={'96'} variant={item} />
    </>
  );
};

// eslint-disable-next-line react/display-name
CardLiveFeedEntity.ItemInfo = observer(
  ({ info }: CardLiveFeedEntityItemInfoProps) => {
    const isClient = useIsClient();
    const isHydrated = useStore().app.isHydrated && isClient;
    const currencySign = useStore().app.getCurrencySign;

    const price = `${currencySign}${info?.price?.current}`;

    return (
      <>
        <div className={styles['price-tags']}>
          {isHydrated ? (
            <>
              <Typography
                className={styles['price-tags--current']}
                size={'xl'}
                weight={'bold'}
              >
                {price}
              </Typography>

              {info?.price?.old && (
                <Typography
                  className={styles['price-tags--old']}
                  decoration={'line-through'}
                  size={'m'}
                  weight={'semi-bold'}
                >
                  {currencySign}
                  {info.price.old}
                </Typography>
              )}
            </>
          ) : (
            <Skeleton style={{ width: '100%' }} />
          )}
        </div>
        <Typography
          className={cn(styles['title'])}
          size={'base'}
          weight={'medium'}
        >
          {info.title}
        </Typography>
      </>
    );
  },
);
