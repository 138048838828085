import cn from 'clsx';
import React, { useEffect, useRef } from 'react';

import { PopperProps } from '@/shared/ui/desktop/drop-down-wrapper/types';

import styles from './wrapper.module.scss';

export const DropDownWrapper: React.FC<PopperProps> = ({
  children,
  isOpened,
  close,
  className,
  outerRef,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const popperClasses = cn(
    styles.popper,
    {
      [styles.popper__opened]: isOpened,
    },
    className,
  );

  useEffect(() => {
    const handleOutClick = (e: MouseEvent) => {
      e.stopPropagation();
      if (outerRef) {
        if (
          isOpened &&
          outerRef.current &&
          !outerRef.current.contains(e.target as Node)
        ) {
          close();
        }
        return;
      }

      if (isOpened && ref.current && !ref.current.contains(e.target as Node)) {
        close();
      }
    };

    document && document?.addEventListener('click', handleOutClick);
    return () =>
      document && document?.removeEventListener('click', handleOutClick);
  }, [isOpened, close]);

  return (
    <div data-opened={isOpened} ref={outerRef ?? ref} className={popperClasses}>
      {children}
    </div>
  );
};
