import { InventoryItem } from '@/shared/types/common';

export const inventoryItems: InventoryItem[] = [
  {
    id: 'card-random-0',
    propertiesSetId: 'card-random-0',
    item: 'carpet',
    category: 'transport',
    rarity: 'common',
    type: 'exclusive',
    tagCategory: ['fly', 'ride'],
    info: {
      title: 'Carpet Zombie Ponycycle',
      price: {
        current: 23,
      },
      age: 'teen',
    },
    description:
      'The Carpet Zombie Ponycycle is a limited legendary pet in Adopt Me!' +
      ' that was released on June 16, 2022. It can be obtained through hatching' +
      ' a Zodiac Minion Egg; Gru, an NPC, tasked players to hatch this egg. Now that' +
      ' its respective event has ended, it can only be obtained through trading.',
    properties: [
      {
        id: 'card-random-0',
        value: 'card-random-0',
        tagCategory: ['fly', 'ride'],
        price: {
          current: 23,
        },
      },
      {
        id: 'card-random-0-1',
        value: 'card-random-0-1',
        tagCategory: ['mega-neon'],
        price: {
          old: 19,
          current: 13,
        },
      },
      {
        id: 'card-random-0-2',
        value: 'card-random-0-2',
        tagCategory: ['ride'],
        price: {
          old: 76,
          current: 38,
        },
      },
    ],
    timer: 100,
    timesUp: false,
  },
  {
    id: 'card-random-1',
    propertiesSetId: 'card-random-1',
    item: 'caravan',
    category: 'transport',
    rarity: 'uncommon',
    type: 'exclusive',
    tagCategory: ['ride'],
    info: {
      title: 'Caravan Zombie Ponycycle',
      price: {
        old: 86,
        current: 38,
      },
      age: 'teen',
    },
    description:
      'The Caravan Zombie Ponycycle is a limited legendary pet in Adopt Me!' +
      ' that was released on June 16, 2022. It can be obtained through hatching' +
      ' a Zodiac Minion Egg; Gru, an NPC, tasked players to hatch this egg. Now that' +
      ' its respective event has ended, it can only be obtained through trading.',
    properties: [
      {
        id: 'card-random-1',
        value: 'card-random-1-0',
        tagCategory: ['ride'],
        price: {
          old: 86,
          current: 38,
        },
      },
      {
        id: 'card-random-1-1',
        value: 'card-random-1-1',
        tagCategory: ['none', 'fly'],
        price: {
          old: 54,
          current: 31,
        },
      },
    ],
    timer: 10,
    timesUp: false,
  },
  {
    id: 'card-random-2',
    propertiesSetId: 'card-random-2',
    item: 'fly-potion',
    category: 'potions',
    rarity: 'common',
    type: 'exclusive',
    tagCategory: ['neon', 'fly', 'ride'],
    info: {
      title: 'Bottle Zombie Ponycycle',
      price: {
        old: 76,
        current: 12,
      },
      age: 'teen',
    },
    properties: [
      {
        id: 'card-random-2',
        value: 'card-random-2',
        tagCategory: ['neon', 'fly', 'ride'],
        price: {
          old: 76,
          current: 12,
        },
      },
      {
        id: 'card-random-2-1',
        value: 'card-random-2-1',
        tagCategory: ['fly'],
        price: {
          old: 98,
          current: 25,
        },
      },
    ],
    timer: 500,
    timesUp: false,
  },
  {
    id: 'card-random-3',
    propertiesSetId: 'card-random-3',
    item: 'retired-egg',
    category: 'eggs',
    rarity: 'uncommon',
    type: 'exclusive',
    tagCategory: ['mega-neon', 'fly'],
    info: {
      title: 'Retired-egg Zombie Ponycycle',
      price: {
        old: 37,
        current: 32,
      },
      age: 'teen',
    },
    description:
      'The Retired-egg Zombie Ponycycle is a limited legendary pet in Adopt Me!' +
      ' that was released on June 16, 2022. It can be obtained through hatching' +
      ' a Zodiac Minion Egg; Gru, an NPC, tasked players to hatch this egg. Now that' +
      ' its respective event has ended, it can only be obtained through trading.',
    properties: [
      {
        id: 'card-random-3',
        value: 'card-random-3',
        tagCategory: ['mega-neon', 'fly'],
        price: {
          old: 37,
          current: 32,
        },
      },
      {
        id: 'card-random-3-2',
        value: 'card-random-3-2',
        tagCategory: ['ride'],
        price: {
          old: 43,
          current: 25,
        },
      },
    ],
    timer: 300,
    timesUp: false,
  },
  {
    id: 'card-random-4',
    propertiesSetId: 'card-random-4',
    item: 'baku',
    category: 'pets',
    rarity: 'uncommon',
    type: 'exclusive',
    tagCategory: ['neon', 'neon'],
    info: {
      title: 'Baku Zombie Ponycycle',
      price: {
        old: 91,
        current: 81,
      },
      age: 'teen',
    },
    description:
      'The Baku Zombie Ponycycle is a limited legendary pet in Adopt Me!' +
      ' that was released on June 16, 2022. It can be obtained through hatching' +
      ' a Zodiac Minion Egg; Gru, an NPC, tasked players to hatch this egg. Now that' +
      ' its respective event has ended, it can only be obtained through trading.',
    properties: [
      {
        id: 'card-random-4',
        value: 'card-random-4',
        tagCategory: ['neon', 'neon'],
        price: {
          old: 91,
          current: 81,
        },
      },
      {
        id: 'card-random-4-1',
        value: 'card-random-4-1',
        tagCategory: ['mega-neon', 'fly'],
        price: {
          old: 13,
          current: 3,
        },
      },
    ],
    timer: 100,
    timesUp: false,
  },
  {
    id: 'card-random-5',
    propertiesSetId: 'card-random-5',
    item: 'buffalo',
    category: 'pets',
    rarity: 'uncommon',
    type: 'exclusive',
    tagCategory: ['fly', 'fly'],
    info: {
      title: 'Buffalo Zombie Ponycycle',
      price: {
        old: 82,
        current: 38,
      },
      age: 'teen',
    },
    properties: [
      {
        id: 'card-random-5',
        value: 'card-random-5',
        tagCategory: ['fly', 'fly'],
        price: {
          old: 82,
          current: 38,
        },
      },
      {
        id: 'card-random-5-1',
        value: 'card-random-5-1',
        tagCategory: ['ride', 'fly'],
        price: {
          old: 376,
          current: 123,
        },
      },
    ],
    timer: 1000,
    timesUp: false,
  },
  {
    id: 'card-random-6',
    propertiesSetId: 'card-random-6',
    item: 'woodland-egg',
    rarity: 'common',
    category: 'eggs',
    type: 'exclusive',
    tagCategory: ['neon', 'fly', 'ride'],
    info: {
      title: 'Woodland-egg Zombie Ponycycle',
      price: {
        old: 492,
        current: 89,
      },
      age: 'teen',
    },
    description:
      'The Woodland-egg Zombie Ponycycle is a limited legendary pet in Adopt Me!' +
      ' that was released on June 16, 2022. It can be obtained through hatching' +
      ' a Zodiac Minion Egg; Gru, an NPC, tasked players to hatch this egg. Now that' +
      ' its respective event has ended, it can only be obtained through trading.',
    properties: [
      {
        id: 'card-random-6',
        value: 'card-random-6',
        tagCategory: ['neon', 'fly', 'ride'],
        price: {
          old: 922,
          current: 89,
        },
      },
      {
        id: 'card-random-6-1',
        value: 'card-random-6-1',
        tagCategory: ['mega-neon', 'fly'],
        price: {
          old: 212,
          current: 22,
        },
      },
    ],
    timer: 300,
    timesUp: false,
  },
  {
    id: 'card-random-7',
    propertiesSetId: 'card-random-7',
    item: 'minion',
    category: 'pets',
    rarity: 'ultra-rare',
    type: 'exclusive',
    tagCategory: ['neon', 'fly', 'ride'],
    info: {
      title: 'Minion Zombie Ponycycle',
      price: {
        old: 558,
        current: 25,
      },
      age: 'teen',
    },
    description:
      'The Minion Zombie Ponycycle is a limited legendary pet in Adopt Me!' +
      ' that was released on June 16, 2022. It can be obtained through hatching' +
      ' a Zodiac Minion Egg; Gru, an NPC, tasked players to hatch this egg. Now that' +
      ' its respective event has ended, it can only be obtained through trading.',
    properties: [
      {
        id: 'card-random-7',
        value: 'card-random-7',
        tagCategory: ['neon', 'fly', 'ride'],
        price: {
          old: 58,
          current: 25,
        },
      },
      {
        id: 'card-random-7-1',
        value: 'card-random-7-1',
        tagCategory: ['mega-neon', 'fly'],
        price: {
          old: 353,
          current: 54,
        },
      },
    ],
    timer: 600,
    timesUp: false,
  },
  {
    id: 'card-random-8',
    propertiesSetId: 'card-random-8',
    item: 'box',
    category: 'new',
    rarity: 'common',
    type: null,
    tagCategory: ['ride'],
    info: {
      title: 'Box Zombie Ponycycle',
      price: {
        old: 70,
        current: 40,
      },
      age: 'teen',
    },

    properties: [
      {
        id: 'card-random-8',
        value: 'card-random-8',
        tagCategory: ['ride'],
        price: {
          old: 70,
          current: 40,
        },
      },
    ],
    timer: 800,
    timesUp: false,
  },
  {
    id: 'card-random-9',
    propertiesSetId: 'card-random-9',
    item: 'japan-egg',
    category: 'eggs',
    rarity: 'legendary',
    type: null,
    tagCategory: ['ride'],
    info: {
      title: 'Japan-egg Zombie Ponycycle',
      price: {
        old: 15,
        current: 11,
      },
      age: 'teen',
    },
    properties: [
      {
        id: 'card-random-9',
        value: 'card-random-9',
        tagCategory: ['fly'],
        price: {
          current: 11,
        },
      },
    ],
    timer: 50,
    timesUp: false,
  },
  {
    id: 'card-random-10',
    propertiesSetId: 'card-random-0',
    item: 'cow',
    category: 'pets',
    rarity: 'common',
    type: 'exclusive',
    tagCategory: ['fly', 'ride'],
    info: {
      title: 'Carpet Zombie Ponycycle',
      price: {
        current: 23,
      },
      age: 'teen',
    },
    description:
      'The Carpet Zombie Ponycycle is a limited legendary pet in Adopt Me!' +
      ' that was released on June 16, 2022. It can be obtained through hatching' +
      ' a Zodiac Minion Egg; Gru, an NPC, tasked players to hatch this egg. Now that' +
      ' its respective event has ended, it can only be obtained through trading.',
    properties: [
      {
        id: 'card-random-0',
        value: 'card-random-0',
        tagCategory: ['fly', 'ride'],
        price: {
          current: 23,
        },
      },
      {
        id: 'card-random-0-1',
        value: 'card-random-0-1',
        tagCategory: ['mega-neon'],
        price: {
          old: 19,
          current: 13,
        },
      },
    ],
    timer: 100,
    timesUp: false,
  },
  {
    id: 'card-random-11',
    propertiesSetId: 'card-random-10',
    item: 'rock',
    rarity: 'common',
    category: 'pets',
    type: 'exclusive',
    tagCategory: ['fly', 'ride'],
    info: {
      title: 'Carpet Zombie Ponycycle',
      price: {
        current: 23,
      },
      age: 'teen',
    },
    description:
      'The Carpet Zombie Ponycycle is a limited legendary pet in Adopt Me!' +
      ' that was released on June 16, 2022. It can be obtained through hatching' +
      ' a Zodiac Minion Egg; Gru, an NPC, tasked players to hatch this egg. Now that' +
      ' its respective event has ended, it can only be obtained through trading.',
    properties: [
      {
        id: 'card-random-0',
        value: 'card-random-0',
        tagCategory: ['fly', 'ride'],
        price: {
          current: 23,
        },
      },
      {
        id: 'card-random-0-1',
        value: 'card-random-0-1',
        tagCategory: ['mega-neon'],
        price: {
          old: 19,
          current: 13,
        },
      },
    ],
    timer: 100,
    timesUp: false,
  },
];
