import cn from 'clsx';

import { ToastEntity } from '@/entities';
import { Avatar, Illustration, Typography } from '@/shared/ui';

import styles from './card-player-info.module.scss';
import { CardPlayerInfoProps } from './card-player-info.types';

const isNicknameValid = (str?: string) => {
  return str && str.length > 4;
};
export const CardPlayerInfoEntity = ({
  className,
  src,
  playerInfo,
  ...props
}: CardPlayerInfoProps) => {
  const nickname = isNicknameValid(playerInfo.nickname)
    ? `@${playerInfo.nickname}`
    : 'Unknown';

  return (
    <div className={cn(styles['container'], className)} {...props}>
      <div className={styles['icon-text']}>
        <Avatar
          isMobile={true}
          hasNotifications={false}
          isBordered
          className={styles['avatar']}
          src={
            src || 'https://ekonom73.ru/wp-content/uploads/2021/05/unnamed2.jpg'
          }
        />
        <div className={styles['text']}>
          <Typography className={styles['name']}>
            {playerInfo.name || 'Unknown'}
          </Typography>
          <Typography className={styles['nickname']}>{nickname}</Typography>
        </div>
      </div>

      <Illustration
        name={'arrow-right'}
        spriteName={'icons'}
        spanTagClassName={styles['icon']}
      />
    </div>
  );
};
