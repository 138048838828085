'use client';

import { useTimer } from '@hooks/client';
import cn from 'clsx';
import { useEffect, useMemo, useState } from 'react';

import { Illustration } from '@/shared/ui';
import { HeightAnimatedWrapper } from '@/shared/ui/height-animated-wrapper/height-animated-wrapper';
import { Typography } from '@/shared/ui/typography';

import styles from './additional-info.module.scss';
import {
  AdditionalInfoMobileIconSvgProps,
  AdditionalInfoMobileProps,
  AdditionalInfoMobileTimerProps,
  Statuses,
} from './additional-info.types';

export const AdditionalInfoMobile = ({
  className,
  children,
  variant,
  width = 'full-w',
  isExpandable = false,
  icon = <AdditionalInfoMobile.DefaultIcon variant={variant} />,
  ...props
}: AdditionalInfoMobileProps) => {
  const additionalInfoClasses = cn(
    styles['additional-info'],
    styles[`variant-${variant}`],
    styles[`width-${width}`],
    className,
  );

  /*@TODO variant danger добавить*/

  if (isExpandable) {
    return (
      <AdditionalInfoMobile.Expandable
        width={width}
        variant={variant}
        icon={icon}
      >
        {children}
      </AdditionalInfoMobile.Expandable>
    );
  }

  return (
    <div className={additionalInfoClasses} {...props}>
      <Typography className={styles['info']}>{children}</Typography>
      {icon}
    </div>
  );
};

const min = 60;

// eslint-disable-next-line react/display-name
AdditionalInfoMobile.Timer = ({
  width = 'full-w',
  className,
  duration = 3600,
  status,
  onTimesOut,
  ...props
}: AdditionalInfoMobileTimerProps) => {
  const statuses: Statuses = {
    timesUp: {
      variant: 'danger',
      icon: 'timer-cancel',
      text: 'Time\'s up',
    },
    completed: {
      variant: 'success',
      icon: 'check-circle',
      text: 'Withdrawn',
    },
    canceled: {
      variant: 'danger',
      icon: 'close-circle',
      text: 'Cancelled by timeout',
    },
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const {
    timer,
    count,
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useTimer({
    duration,
  });

  const variant = status ? statuses[status].variant : 'danger';
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (!count) {
      onTimesOut && onTimesOut();
    }
  }, [count]);

  const additionalInfoClasses = cn(
    styles['additional-info'],
    styles[`variant-${variant}`],
    styles[`width-${width}`],
    className,
  );

  return (
    <div className={additionalInfoClasses} {...props}>
      <Typography className={styles['info-timer']}>
        {status ? statuses[status].text : 'Claim within'}
      </Typography>
      <div className={styles['timer-icon']}>
        <Typography className={styles['info-timer']}>
          {count ? timer : ''}
        </Typography>
        <Illustration
          spanTagClassName={cn(
            styles['additional-info-icon-timer'],
            styles[`additional-info-icon--${variant}`],
          )}
          spriteName={'icons'}
          name={status ? statuses[status].icon : 'clock'}
        />
      </div>
    </div>
  );
};

// eslint-disable-next-line react/display-name
AdditionalInfoMobile.Expandable = ({
  variant,
  width,
  className,
  children,
  icon,
  ...props
}: Omit<AdditionalInfoMobileProps, 'isExpandable'>) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const additionalInfoClasses = cn(
    styles[`variant-${variant}`],
    styles[`width-${width}`],
    styles['expandable'],
    className,
  );
  const infoClasses = cn(styles['info'], { [styles['info-on']]: isExpanded });

  const handleExpand = () => {
    setIsExpanded(prev => !prev);
  };

  return (
    <div className={additionalInfoClasses} {...props} onClick={handleExpand}>
      <div className={styles['icon']}>{icon}</div>
      <HeightAnimatedWrapper className={styles['info-container']}>
        <Typography className={infoClasses}>{children}</Typography>
      </HeightAnimatedWrapper>
      <Illustration
        spanTagClassName={cn(
          styles['cheveron'],
          styles[`cheveron-${isExpanded ? 'up' : 'down'}`],
          styles[`additional-info-icon--${variant}`],
        )}
        spriteName={'icons'}
        name={'chevron-down'}
      />
    </div>
  );
};

// eslint-disable-next-line react/display-name
AdditionalInfoMobile.DefaultIcon = ({
  size,
  width,
  height,
  variant,
  name,
  ...props
}: AdditionalInfoMobileIconSvgProps) => {
  return (
    <Illustration
      spanTagClassName={cn(
        styles['additional-info-icon'],
        styles[`additional-info-icon--${variant}`],
      )}
      spriteName={'icons'}
      name={
        name
          ? name
          : variant === 'success'
            ? 'info-square'
            : variant === 'danger'
              ? 'info-circle'
              : 'info-triangle-warn'
      }
    />
  );
};
