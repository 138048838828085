import { ProfileHistoryTableData } from '@widgets/desktop/profile-history/ui/profile-history.types';
import {
  TableColumns,
  TablePurchasesRows,
  TableTopUpRows,
  TableWithdrawRows,
} from '@widgets/desktop/profile-history/ui/profile-history.types';
import { sample, sampleSize } from 'lodash';

export const tablePurchasesCols: TableColumns = {
  type: {
    title: 'Type',
  },
  item: {
    title: 'Item',
  },
  properties: {
    title: 'Properties',
  },
  price: {
    title: 'Price',
  },
  id: {
    title: 'ID',
  },
  date: {
    title: 'Date',
  },
};

export const tableWithdrawCols: TableColumns = {
  nickname: {
    title: 'Nickname',
  },
  status: {
    title: 'Status',
  },
  item: {
    title: 'Item',
  },
  properties: {
    title: 'Properties',
  },
  price: {
    title: 'Price',
  },
  id: {
    title: 'ID',
  },
  date: {
    title: 'Date',
  },
};

export const tableTopUpCols: TableColumns = {
  status: {
    title: 'Status',
  },
  method: {
    title: 'Method',
  },
  total: {
    title: 'Total',
  },
  id: {
    title: 'ID',
  },
  date: {
    title: 'Date',
  },
};

export const tablePurchasesRows: TablePurchasesRows[] =
  generateLargeDataset(); /*[
  {
    id:  634563 ,
    date: '04.03.24',
    price: '2.34',
    type: 'Pet',
    properties: ['neon'],
    item: {
      item: 'buffalo',
      rarity: 'rare',
      age: 'pre-teen',
      name: 'Zombie Buffalo'
    }
  },
];*/

export const tableWithdrawRows: TableWithdrawRows[] = [
  {
    id: 634563,
    date: '04.03.24',
    price: '2.34',
    properties: ['neon'],
    status: {
      text: 'Ready for withdraw',
      code: 2,
    },
    nickname: 'Aladin',
    item: {
      item: 'buffalo',
      rarity: 'rare',
      age: 'pre-teen',
      name: 'Zombie Buffalo',
    },
  },
];

export const tableTopUpRows: TableTopUpRows[] = [
  {
    id: 634563,
    date: '04.03.24',
    status: {
      text: 'Pending',
      code: 1,
    },
    method: 'Card',
    total: '2.34',
  },
];

function generateLargeDataset(): TablePurchasesRows[] {
  const items: TablePurchasesRows[] = [];

  for (let i = 0; i < 100000; i++) {
    const newItem: TablePurchasesRows = {
      id: (Math.random() * 100000).toFixed(0), // Generate a unique ID for each item
      date: `04.03.${2024 + Math.floor(Math.random() * 365)}`,
      price: (Math.random() * 1000).toFixed(2),
      type: sample(['Pet', 'Potion', 'Transport', 'Eggs']),
      properties: sampleSize(['neon', 'mega-neon', 'ride', 'fly'], 4),
      item: {
        item: sample([
          'minion',
          'buffalo',
          'neko',
          'rock',
          'cow',
          'snowball',
          'retired-egg',
          'japan-egg',
          'woodland-egg',
          'fly-potion',
          'baku',
          'carpet',
          'feesh',
          'caravan',
          'box',
        ]),
        rarity: sample([
          'common',
          'uncommon',
          'ultra-rare',
          'rare',
          'legendary',
        ]),
        age: sample([
          'new-born',
          'junior',
          'pre-teen',
          'teen',
          'post-teen',
          'full-grown',
        ]),
        name: `${i} ${sample(['Buffalo', 'Cow', 'Sheep'])}`,
      },
    };
    items.push(newItem);
  }

  return items;
}

export const testData: ProfileHistoryTableData = {
  'top-up': {
    columns: tableTopUpCols,
    rows: tableTopUpRows,
  },

  withdraw: {
    columns: tableWithdrawCols,
    rows: tableWithdrawRows,
  },

  purchases: {
    columns: tablePurchasesCols,
    rows: tablePurchasesRows,
  },
};
