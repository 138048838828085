'use client';
import { createContext, ReactNode, useContext } from 'react';

//import { enableStaticRendering } from 'mobx-react-lite';
import type { RootStore } from './root';

const StoreContext = createContext<RootStore | null>(null);
//enableStaticRendering(typeof window === 'undefined');
interface StoreProviderProps {
  store: RootStore;
  children: ReactNode;
}

export function StoreProvider(props: StoreProviderProps) {
  return (
    <StoreContext.Provider value={props.store}>
      {props.children}
    </StoreContext.Provider>
  );
}

export function useStore(): RootStore;
export function useStore<Result>(
  selector: (value: RootStore) => Result,
): Result;
export function useStore<Result>(selector?: (value: RootStore) => Result) {
  const store = useContext(StoreContext);

  if (!store) {
    throw new Error('Can not `useStore` outside of the `StoreProvider`');
  }

  if (typeof selector === 'function') {
    return selector(store);
  }

  return store;
}
