import { getCookie } from 'cookies-next';
import axios from 'xior';

const usersApi = axios.create({
  baseURL: 'https://users.shipblox.com',
});

usersApi.interceptors.request.use(function (config) {
  const token = getCookie('jwt');
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

export default usersApi;
