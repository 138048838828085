'use client';
import { CardPriceItemPageProps } from '@entities/desktop/cards/card-price-item-page/ui/card-price-item-page.types';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import { useIsClient } from 'usehooks-ts';

import { Skeleton } from '@/shared/ui';
import { Illustration } from '@/shared/ui/illustration';
import { Typography } from '@/shared/ui/typography';
import { useStore } from '@/store/context';

import styles from './card-price-item-page.module.scss';
export const CardPriceItemPageEntity = observer(
  ({
    children,
    className,
    priceInfo: { old, current },
    text,
    ...props
  }: CardPriceItemPageProps) => {
    const cardLiveFeedClasses = cn(styles['card-price-item-page'], className);
    const currencySign = useStore().app.getCurrencySign;
    const isClient = useIsClient();
    const isHydrated = useStore().app.isHydrated && isClient;

    return (
      <div className={cardLiveFeedClasses} {...props}>
        <div className={styles['content']}>
          <div className={styles['price-info']}>
            {isHydrated ? (
              <>
                {' '}
                <Typography className={styles['price-info--current']}>
                  {currencySign}
                  {current}
                </Typography>
                {old && (
                  <Typography
                    decoration={'line-through'}
                    className={styles['price-info--old']}
                  >
                    {currencySign}
                    {old}
                  </Typography>
                )}
              </>
            ) : (
              <Skeleton style={{ height: 30, width: '100%' }} />
            )}
          </div>
          <Typography className={styles['text']}>{text}</Typography>
        </div>
        <div className={styles.background}>
          <Illustration
            size={196}
            spanTagClassName={styles['card-decoration']}
            name={'card-decoration'}
          />
        </div>
      </div>
    );
  },
);
