'use client';
import { CardItemProps } from '@entities/desktop/cards/card-item/ui/card-item.types';
import cn from 'clsx';
import React from 'react';

import {
  ItemRarityCard,
  ItemRarityLiveFeed,
  TagInfo,
  Typography,
} from '@/shared/ui';
import { CardItemInfo, CardItemTop } from '@/shared/ui/cards';
import { ItemRarityShopCardBg } from '@/shared/ui/desktop/item-rarity-shop-card-bg';

import styles from './card-item.module.scss';
export const CardItemEntity = ({
  children,
  className,
  variant = 'primary',
  rarity,
  info,
  tagsInfo,
  item,
  bottomSlot,
  topRightSlot,
  onClick = () => {},
  propertiesSlot,
  applyHandleClickOnFullCard = false,
  disabled,
  ...props
}: CardItemProps) => {
  const cardItemClasses = cn(
    styles['card-item'],
    styles['variant'],
    styles[`variant--${variant}`],
    { [styles['card-item--pointer']]: applyHandleClickOnFullCard },
    { [styles['disabled']]: disabled },
    className,
  );

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    return applyHandleClickOnFullCard ? onClick(e) : undefined;
  };

  return (
    <article onClick={handleClick} className={cardItemClasses} {...props}>
      <div onClick={handleClick} className={cn(styles['content'])}>
        <CardItemTop
          variant={variant}
          className={styles['item']}
          tagsInfo={tagsInfo}
          onClick={onClick}
          item={item}
          topRightSlot={topRightSlot}
          backgroundIllustration={
            variant !== 'tertiary' ? (
              variant !== 'quaternary' ? (
                <ItemRarityCard
                  className={styles['item-rarity-card']}
                  size={'75'}
                  variant={rarity}
                />
              ) : (
                <ItemRarityLiveFeed
                  size={'100'}
                  variant={rarity}
                  className={styles['item-rarity-live-feed']}
                />
              )
            ) : (
              <ItemRarityShopCardBg
                className={styles['item-rarity-shop-card']}
                size={'m'}
                variant={rarity}
              />
            )
          }
        />
        <CardItemInfo
          applyHandleClickOnFullCard={applyHandleClickOnFullCard}
          onTitleClick={onClick}
          info={info}
        />

        {propertiesSlot && (
          <div onClick={handleClick} className={styles['properties-slot']}>
            {propertiesSlot}
          </div>
        )}
      </div>

      {variant !== 'tertiary' && variant !== 'quaternary' && (
        <div className={styles['bottom-slot']}>{bottomSlot}</div>
      )}
      {variant === 'tertiary' && bottomSlot}
    </article>
  );
};
