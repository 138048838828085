import { CardItemBlockProps } from '@entities/desktop/cards/card-item-block/ui/card-item-block.types';
import cn from 'clsx';

import { Item } from '@/shared/ui';
import { ItemRarityItemPage } from '@/shared/ui';

import styles from './card-item-block.module.scss';
export const CardItemBlockEntity = ({
  children,
  className,
  item,
  rarity,
  ...props
}: CardItemBlockProps) => {
  const cardLiveFeedClasses = cn(styles['card-item-block'], className);

  return (
    <article className={cardLiveFeedClasses} {...props}>
      <ItemRarityItemPage
        isDesktop
        className={styles['item-rarity']}
        variant={rarity}
      />
      <Item className={styles['item']} size={'152'} variant={item} />
    </article>
  );
};
