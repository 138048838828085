import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useIsClient } from 'usehooks-ts';

import { InputPriceProps } from '@/shared/ui/desktop/input-price/types';
import { Typography } from '@/shared/ui/typography';
import { useStore } from '@/store/context';

import styles from './input-price.module.scss';

export const InputPrice: React.FC<InputPriceProps> = ({
  maxValue,
  minValue,
  label,
  value,
  className,
  setValue,
  onSave,
}) => {
  const [inputValue, setInputValue] = useState<number>(value);
  const containerClasses = cn(styles.container, className);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    if (/^[0-9]+$/.test(newValue)) {
      setInputValue(Number(newValue));
    }
  };

  const saveNewValue = () => {
    if (inputValue > maxValue) {
      setValue(maxValue);
    } else if (inputValue < minValue) {
      setValue(minValue);
    } else {
      setValue(inputValue);
    }
  };

  const onBlur = () => {
    setInputValue(value);
    saveNewValue();
    onSave(Number(inputValue));
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <div className={containerClasses}>
      <Typography weight="medium" className={styles.label}>
        {label}
      </Typography>
      <div className={styles['input-container']}>
        <Currency />
        <input
          value={inputValue}
          onBlur={onBlur}
          onChange={handleChange}
          className={styles['value-input']}
        />
      </div>
    </div>
  );
};

const Currency = observer(() => {
  const currencySign = useStore().app.getCurrencySign;
  const isHydrated = useStore().app.isHydrated;
  const isClient = useIsClient();

  if (!isClient || !isHydrated) {
    return null;
  }

  return (
    <Typography className={styles.currency} weight="medium">
      {currencySign}
    </Typography>
  );
});
