import { SignInData } from '@features/auth-modal-content/ui/sign-in-form/types';
import { SignUpData } from '@features/auth-modal-content/ui/sign-up-form/types';
import { redirect, usePathname, useRouter } from 'next/navigation';

import { SIGN_IN, SIGN_UP } from '@/shared/api/endpoints';
import usersApi from '@/shared/api/usersApi';
import { isRouteProtected } from '@/shared/constants/routes';
import { AuthInfo, AuthResponse } from '@/store/authorization/types';
import { useStore } from '@/store/context';

export const useAuth = () => {
  const authStore = useStore().authorization;
  const router = useRouter();
  const path = usePathname();

  const signIn = async (data: SignInData, redirectPath?: string | null) => {
    try {
      const response = await usersApi.post<AuthResponse>(SIGN_IN, data);
      authStore.setAuthInfo(response.data.data);
      authStore.closeModal();
      if (redirectPath) router.replace(redirectPath);
    } catch (err) {
      authStore.setAuthErrorMessage('Invalid inputs');
      authStore.setStep(0);
    }
  };
  const signUp = async (data: SignUpData) => {
    try {
      const response = await usersApi.post<AuthResponse>(SIGN_UP, data);
      authStore.setAuthInfo(response.data.data);
      authStore.closeModal();
    } catch (err: any) {
      authStore.setAuthErrorMessage(err.response.data.message);
      authStore.setStep(0);
    }
  };

  const logOut = () => {
    authStore.logOut();
    if (isRouteProtected(path)) {
      router.replace('/');
    }
  };

  return { signIn, signUp, logOut };
};
