import cn from 'clsx';
import React from 'react';

import { DivElementType } from '@/shared/types/common';
import { Illustration } from '@/shared/ui';

import styles from './styles.module.scss';

interface CheckBoxProps extends DivElementType {
  checked: boolean;
  onChange: () => void;
  children?: React.ReactElement;
}

const Checkbox: React.FC<CheckBoxProps> = ({
  checked,
  onChange,
  children,
  className,
}) => {
  return (
    <label className={cn(styles.container, className)}>
      <input
        type="checkbox"
        className={styles.check_input}
        checked={checked}
        onChange={onChange}
      />
      <span role={'checkbox'} className={styles.check_box}>
        {checked && (
          <Illustration
            name={'check'}
            spriteName={'icons'}
            size={18}
            style={{ color: '#FFFFFF' }}
          />
        )}
      </span>
      {children}
    </label>
  );
};

export default Checkbox;
